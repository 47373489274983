body {
  margin: 0;
  background-color: #121212;
  color: #ffffff;
  font-family: "Montserrat", "League Spartan", sans-serif;
  text-align: center;
  overflow: hidden; /* Hide scrollbars */
}

button:active {
  outline: none;
  border: none;
}

button:focus {
  outline: 0;
}

a {
  color: inherit;
}

ul {
  padding-left: 0px;
}

#gameTitle {
  padding: 40px 0px 10px 0px;
  font-size: 45px;
  color: #1db954;
  font-weight: 600;
  /* font-family: "Bungee Shade", cursive; */
  font-family: "Montserrat", sans-serif;
}

.card {
  align-items: center;
  width: 175px;
  display: flex;
  /* height: 270px; */
  /* background-color: #1db954; */
  color: #faf9f6;
  text-align: center;
  border-radius: 10px;
  font-family: "Noto Sans", sans-serif;
}

.card:hover {
  cursor: pointer;
}

.cardOpened {
  width: 300px;
  background-color: #212121;
}

.cardBottomRow {
  display: flex;
  justify-content: space-between;
}

.cardPoint {
  width: 20px;
  height: 20px;
}

.lastCard {
  width: 100px;
}

.cardInfoContainer {
  width: 150px;
  padding: 20px 0px;
}

.cardInfo {
  /* margin-top: 10px; */
  text-align: left;
  width: 100%;
  margin: 0px;
}

.cardTitle {
  text-overflow: ellipsis;
  overflow: hidden;
}

.cardArtist {
  font-size: small;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #bab5b5;
}

.cardDate {
  color: #bab5b5;
  font-size: small;
}

.scroll {
  /* background-color: #333; */
  white-space: nowrap;
  overflow: hidden;
}

.timelineCard {
  /* height: 400px; */
  display: inline-block;
  /* color: white; */
  text-align: right;
  padding: 0px 14px;
  text-decoration: none;
  padding-left: 0px;
  font-weight: 600;
  font-family: "Montserrat", "Sarabun", sans-serif;
}

.timelineCards {
  width: 95%;
  margin: 0 auto;
  overflow: auto;
  height: 375px;
  overflow: auto;
  overflow-y: hidden;
}

/* .timelineCard:hover {
  background-color: #777;
} */

.cardPoster {
  padding-top: 10px;
  width: 150px;
  height: 150px;
  display: block;
  margin: auto;
}

.nextCard {
  height: 200px;
}

.boardGame {
  height: 375px;
  margin-top: 50px;
  /* overflow-y: hidden; */
  /* position: relative; */
  bottom: 0px;
}

.lostModal {
  background-color: #212121;
  margin: auto;
  width: 50%;
  border: 3px solid #1db954;
  padding: 10px;
  border-radius: 20px;
  padding-bottom: 20px;
}

.loseButton {
  color: black;
  border: none;
  border-radius: 5px;
  color: white;
  padding: 6px;

  /* width: 70px;
  height: 70px; */
  background-color: #1db954;
  font-size: 14px;
  /* margin: 10px; */
  border: 2px solid #1db954;
}

.loseScreenWording {
  font-size: 30px;
}

.shareIcon {
  width: 22px;
  padding-right: 8px;
  margin-bottom: 5px;
}

.replayIcon {
  width: 24px;
  padding-right: 5px;
  margin-bottom: 2px
}

.hoveredRight {
  background-color: #2c2c2c !important;
}

.hoveredWrong {
  background-color: #d13939 !important;
}

.cardRight {
  background-color: #212121;
}

.cardWrong {
  /* background-color: #9f2d4d !important; */
  background-color: #ca1818;
}

.loseTable {
  width: 100%;
}

.pause {
  position: absolute;
  right: 30px;
  top: 25px;
  width: 40px;
  height: 40px;
  /* color: #1db954; */
  /* border: 3px solid #1db954; */
  border-radius: 50%;
  background-color: inherit;
}

.pause:hover {
  background-color: #212121;
  color: black;
  cursor: pointer;
}

.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
}

.modal.enter-done {
  opacity: 1;
  pointer-events: visible;
}

.modal.exit {
  opacity: 0;
}

.album-modal-content {
  background-color: transparent !important;
}

.modal-content {
  width: 500px;
  overflow-y: scroll;
  background-color: #212121;
  transition: all 0.3s ease-in-out;
  transform: translateY(-200px);
}

.modal.enter-done .modal-content {
  transform: translateY(0);
}

.modal.exit .modal-content {
  transform: translateY(-200px);
}

.modal-header,
.modal-footer {
  border-bottom: none;
  justify-content: right;
  padding: 10px 5px;
}

.modal-title {
  margin: 0;
}

.album-modal-body {
  padding: 0px;
}

.modal-body {
  padding: 0px 20px 20px 20px;
  text-align: left;
  /* border-top: 1px solid #eee;
  border-bottom: 1px solid #eee; */
}

.pause-screen-spotify-div {
  border-top: 1px solid white;
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.spotify-information {
  margin-top: 10px;
}

.pause-screen-text {
  font-size: 14px;
  margin-bottom: 10px;
}

.spotify-screen-ex {
  width: 60%;
  align-self: center;
  margin-bottom: 10px;
}

.button {
  border-radius: 50%;
  border: none;
  background-color: inherit;
  color: #ffffff;
}

.modal-titles {
  color: #1db954;
  font-weight: 800;
}

.rightCardExample {
  border: 2px solid black;
  border-radius: 5px;
}

.card-examples {
  width: 100%;
  text-align: center;
}

.copyright-highlight:hover {
  color: #1db954;
  text-decoration: solid;
}

.copyright-div {
  padding-top: 20px;
  border-top: 1px solid white;
}

.copyright {
  /* font-family: ; */
  font-size: 12px;
  margin-bottom: 0;
}

.modal-footer {
  display: block;
  margin: 0px 20px;
}

.owner {
  /* font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif; */
  text-align: center;
  font-size: 10px;
  margin: 5px;
}

.owner-link {
  color: #1db954;
}

.owner-link:hover {
  color: #1db954;
  text-decoration: solid;
}

.hearts {
  width: 50px;
}

/* #shareScore{
  margin-left: 100px;
}

#playAgain{
  margin-right: 100px;
} */

@media only screen and (max-width: 1024px) {
  #gameTitle {
    font-size: 50px;
  }
  .lostModal {
    width: 90%;
    padding-bottom: 20px;
  }
  .nextCard {
    height: 200px;
  }
  .boardGame {
    height: 320px;
    padding-top: 20px;
    /* overflow-y: hidden; */
    /* position: relative; */
    bottom: 0px;
  }
  /* .cardPoster {
    padding-top: 5px;
    width: 180px;
  }

  .card {
    align-items: center;
    width: 200px;
    border-radius: 10px;
  } */

  .lastCard {
    width: 100px;
  }

  .loseButton {
    width: 140px;
  }

  /* .cardInfoContainer {
    width: 180px;
  }
  .cardInfo {
    margin-top: 0px;
    font-size: 15px;
    width: 100%;
  } */
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 800px) {
  .pause {
    top: 10px;
    right: 10px;
    font-size: 15px;
    height: 30px;
    width: 30px;
  }

  .modal-content {
    max-height: 80%;
  }

  #gameTitle {
    padding-top: 50px;
    font-size: 25px;
  }

  .hearts {
    width: 30px;
  }

  h1 {
    font-size: 20px;
  }

  .lostModal {
    width: 90%;
    padding-bottom: 20px;
  }

  .loseButton {
    width: 125px;
  }

  .nextCard {
    height: 150px;
  }

  .boardGame {
    height: 300px;
    padding-top: 20px;
    /* overflow-y: hidden; */
    /* position: relative; */
    bottom: 0px;
  }

  .loseScreenWording {
    font-size: 20px !important;
  }

  .cardPoster {
    padding-top: 5px;
    width: 150px;
  }

  .lastCard {
    width: 120px;
  }

  .card {
    align-items: center;
    width: 125px;
    border-radius: 10px;
  }

  .cardInfoContainer {
    width: 115px;
    padding: 10px 0px;
  }

  .cardInfo {
    font-size: 12px;
  }

  .cardPoster {
    width: 115px;
    height: 115px;
  }

  .cardPoint {
    width: 18px;
    height: 18px;
  }
}
